"use client";
import React from "react";
import { App as AntApp } from "antd";
import { Provider } from "react-redux";
import store from "./src/store/store";
import { ConfigProvider } from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import dayjs from "dayjs";
dayjs.locale("en");

const App = ({ element }) => {
  return (
    <AntApp>
      <Provider store={store}>
        <ConfigProvider locale={enUSIntl}>{element}</ConfigProvider>
      </Provider>
    </AntApp>
  );
};

export default App;
