import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupplierForPoBuild } from '@/gql/graphql';

interface SupplierState {
  selectedSupplier: SupplierForPoBuild | null;
}

const initialState: SupplierState = {
  selectedSupplier: {
    brand_id: null,
    supplier_id: '',
    supplier_name: null
  }  
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setSelectedSupplier: (state, action: PayloadAction<SupplierForPoBuild | null>) => {
      state.selectedSupplier = action.payload;
    },
    clearSelectedSupplier: (state) => {
      state.selectedSupplier = null;
    },
  },
});

export const { setSelectedSupplier, clearSelectedSupplier } = supplierSlice.actions;

export default supplierSlice.reducer;
