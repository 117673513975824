import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  CookieStorage,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
// @ts-ignore
import Cookies from "js-cookie";

const secure = false;

const ClientId = process.env.AWS_CLIENT_ID || "7tn0hm69anauo8bjuvvgr13s36";
console.log(process.env.AWS_CLIENT_ID);
const poolData = {
  UserPoolId: process.env.AWS_USER_POOL_ID || "us-east-1_LzwkL5HhC",
  ClientId,
  Storage: new CookieStorage({ secure }),
};
const userPool = new CognitoUserPool(poolData);

export type AuthenticationDataType = {
  Username: string;
  Password: string;
};
export type AuthenticationCodeType = {
  Username: string;
  code: string;
};
export type AuthForgotPassType = AuthenticationDataType &
  AuthenticationCodeType;
export type AuthChangePasswordType = {
  oldPassword: string;
  newPassword: string;
};
export type AuthAttrsType = {
  [key: string]: string;
};

export async function awsLogin(authenticationData: AuthenticationDataType) {
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userData = {
    Username: authenticationData.Username,
    Pool: userPool,
    Storage: new CookieStorage({ secure }),
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result: CognitoUserSession) {
        const accessToken = result.getAccessToken().getJwtToken();
        resolve(accessToken);
      },

      onFailure: function (err) {
        alert(err);
        reject(err);
      },
    });
  });
}

export async function awsRegister(authenticationData: AuthenticationDataType) {
  const attributeList: CognitoUserAttribute[] = [];

  const dataEmail = {
    Name: "email",
    Value: authenticationData.Username,
  };

  const attributeEmail = new CognitoUserAttribute(dataEmail);

  attributeList.push(attributeEmail);
  return new Promise<{ confirmed?: boolean; destination?: string }>(
    (resolve, reject) => {
      userPool.signUp(
        authenticationData.Username,
        authenticationData.Password,
        attributeList,
        [],
        function (
          err,
          result?: {
            codeDeliveryDetails: { Destination: string };
            userConfirmed?: boolean;
          }
        ) {
          if (err) {
            alert(err.message);
            reject(null);
          }
          resolve({
            confirmed: result?.userConfirmed,
            destination: result?.codeDeliveryDetails?.Destination,
          });
        }
      );
    }
  );
}

export async function awsConfirm(authenticationData: {
  Username: string;
  code: string;
}) {
  const userData = {
    Username: authenticationData.Username,
    Pool: userPool,
    Storage: new CookieStorage({ secure }),
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(
      authenticationData.code,
      true,
      function (err, result) {
        if (err) {
          alert(err.message);
          reject(err);
          return;
        }
        resolve(result);
      }
    );
  });
}

export function getAccessToken() {
  const username = Cookies.get(
    `CognitoIdentityServiceProvider.${ClientId}.LastAuthUser`
  );
  const accessTokenKey = `CognitoIdentityServiceProvider.${ClientId}.${username}.accessToken`;
  return Cookies.get(accessTokenKey);
}

export async function awsLogout() {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) cognitoUser.signOut();
}

export async function awsMe() {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    return new Promise<AuthAttrsType>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cognitoUser.getSession((err: any) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.getUserAttributes(function (
            err,
            attributes?: CognitoUserAttribute[]
          ) {
            if (err) {
              // Handle error
              reject(err);
            } else {
              const attrs: AuthAttrsType = {};
              if (attributes) {
                for (let i = 0; i < attributes.length; i++) {
                  attrs[attributes[i].getName()] = attributes[i].getValue();
                }
              }
              resolve(attrs);
            }
          });
        }
      });
    });
  } else return Promise.reject("401");
}

export async function awsMeUpdate(attribute: AuthAttrsType) {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cognitoUser.getSession((err: any) => {
        if (err) {
          reject(err);
        } else {
          const attributeList: CognitoUserAttribute[] = [];
          for (const key in attribute) {
            attributeList.push(
              new CognitoUserAttribute({
                Name: key,
                Value: attribute[key],
              })
            );
          }
          cognitoUser.updateAttributes(attributeList, function (err, res) {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          });
        }
      });
    });
  } else return Promise.reject("401");
}

export function awsChangePassword(data: AuthChangePasswordType) {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cognitoUser.getSession((err: any) => {
        if (err) {
          return reject(false);
        } else {
          cognitoUser.changePassword(
            data.oldPassword,
            data.newPassword,
            function (err, result) {
              if (err) {
                reject(err.message);
                return;
              }

              resolve(result);
            }
          );
        }
      });
    });
  } else return Promise.reject("401");
}

export async function awsForgotPasswordSendCode(Username: string) {
  const userData = {
    Username: Username,
    Pool: userPool,
    Storage: new CookieStorage({ secure }),
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}
export async function awsResetPassword(Request: AuthForgotPassType) {
  const userData = {
    Username: Request.Username,
    Pool: userPool,
    Storage: new CookieStorage({ secure }),
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(Request.code, Request.Password, {
      onSuccess() {
        resolve(true);
      },
      onFailure(err) {
        alert(err);
        reject(err);
      },
    });
  });
}
