import { BulkPoType, Buyer, InputMaybe, MerchLeadType, ProjectBuildNewProjectType, ProjectBuildPoLineInput, Supplier } from '@/gql/graphql';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProjectBuildPoState {
  formValues: ProjectBuildNewProjectType;
  suppliers: Supplier[],
  buyers: Buyer[];
  poTypes: BulkPoType[];
  merchLeads: MerchLeadType[];
  poLineData: ProjectBuildPoLineInput[];   


}

const initialState: ProjectBuildPoState = {
  formValues: {
    po_id: '',
    supplier_id: '',
    supplier_name: '',
    buyer_id: 0,
    buyer_name: '',
    po_type: 0,
    po_type_name: '',
    lead_name: '',
    lead_id: 0,
    project_name: '',
    project_note: '',
  },
  suppliers: [],
  buyers: [],
  poTypes: [],
  merchLeads: [],
  poLineData: [],
};

export const projectBuildPoSlice = createSlice({
  name: 'projectBuildPo',
  initialState,
  reducers: {
    setNewProjectData: (state, action: PayloadAction<ProjectBuildNewProjectType>) => {
      state.formValues = action.payload;
    },
    updateFormField: (state, action: PayloadAction<Partial<ProjectBuildNewProjectType>>) => {
      state.formValues = { ...state.formValues, ...action.payload };
    },
    clearFormValues: (state) => {
      state.formValues = initialState.formValues;
    },
    setSuppliers: (state, action: PayloadAction<Supplier[]>) => {
      state.suppliers = action.payload;
    },
    setBuyers: (state, action: PayloadAction<Buyer[]>) => {
      state.buyers = action.payload;
    },
    setPoTypes: (state, action: PayloadAction<BulkPoType[]>) => {
      state.poTypes = action.payload;
    },
    setMerchLeads: (state, action: PayloadAction<MerchLeadType[]>) => {
      state.merchLeads = action.payload;
    },
    setPoLineData: (state, action: PayloadAction<ProjectBuildPoLineInput[]>) => {
      state.poLineData = action.payload;
    },
  },
});

export const { setNewProjectData, updateFormField, clearFormValues, setSuppliers, setBuyers, setPoTypes, setMerchLeads, setPoLineData } = projectBuildPoSlice.actions;

export default projectBuildPoSlice.reducer;
