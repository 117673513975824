import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { getAccessToken } from "@/utils/aws";
import { notification } from "antd";

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: getAccessToken(),
    },
  }));

  return forward(operation);
});
console.log('--------------------------------'+process.env.API_GRAPHQL_ENDPOINT);
const httpLink = new HttpLink({
  uri: process.env.API_GRAPHQL_ENDPOINT,
  fetch,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  //   // TODO: logout
  // if (networkError?.statusCode === 401) {
  // }
  if (networkError) console.log(`[Network error]: ${networkError}`);
  if (graphQLErrors) {
    const messages = [];
    graphQLErrors.forEach(({ message, path }) => {
      messages.push(message);
      console.log(`[GraphQL error]: Message: ${message},Path: ${path}`);
    });
    notification.error({
      placement: "topRight",
      message: `${messages}`,
      duration: 3,
    });
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
});

export default client;
